import React from "react"
import { Card } from "react-bootstrap"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import BioImages from "../components/BioImage"
const bio = () => {
  return (
    <Layout>
      <Helmet>
        <title>Bio - Pamela Mary Brown</title>
      </Helmet>
      <h2>Bio</h2>
      <Card style={{ border: "none", backgroundColor: "transparent" }}>
        <Card.Text>
          Pamela Mary Brown, born in Donegal, is Writer-in-Residence at HMP
          Magilligan, Editor of Time In magazine. Creative Writing
          Trainer-Assessor in the North West Regional College. Poet and Author
          who has read her work in Ireland, Buffalo Circle New Mexico, Holland
          and the UK. And also, Glastonbury, Manchester, Bristol, Derry,
          Belfast, Dublin, Mullingar, Galway, Sligo, Cork, Dundalk, and at
          Festivals (including) Electric Picnic and Flatlake Festival.
        </Card.Text>

        <Card.Text>
          Workshop and Creative Writing Facilitator at (many) Public Venues,
          Schools and Institutions (Ireland {"&"} UK). Studied Community Drama
          at the University of Ulster; Media Studies at Foyle Arts Centre,
          Derry.
        </Card.Text>

        <Card.Text>
          She holds a Master of Arts in Creative Writing (Distinction) from
          Teesside University 2020; and a BA (Hons) Degree in English Literature{" "}
          {"&"} Creative Writing. She has lived in Derry, in the North of
          Ireland for 30 years […]
        </Card.Text>

        <Card.Text>
          She cites the Arts and Creative Writing as integral to her work since
          the 1990s. As a Creative Writing Facilitator in (many) Community
          Settings, Schools, and other Institutions. Through the writing and
          recording of social histories, and with first-hand experience of the
          importance of the Creative Process which provides a platform towards
          understanding our shared histories. She has planned, designed,
          delivered, and evaluated creative programmes and lesson-plans as well
          as utilised Creative Writing as positive intervention for
          self-development, self-reflection, advancing technical analysis and
          the understanding of creative work as well as higher methodologies.
        </Card.Text>
        <BioImages />
      </Card>
    </Layout>
  )
}

export default bio
